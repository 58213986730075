<template>
  <div id="support">
    <div class="header">
      <h1>ハッシュタグ管理</h1>
    </div>
    <div class="container">
      <main>
        <section>
          <button @click="prev">戻る</button>
          <button @click="create">新規作成</button>
          <br />
          <c-validation-errors :errors-hash="errors" />
          <table>
              <thead>
                <tr>
                  <th class="text-left">ハッシュタグ名</th>
                  <th class="text-left">並び順</th>
                  <th class="text-left">公式</th>
                  <th class="text-left">編集</th>
                  <th class="text-left">削除</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(hashtag,index) in hashtags" :key="index">
                  <td>{{ hashtag.name }}</td>
                  <td>{{ hashtag.sort }}</td>
                  <td>{{hashtag.official}}</td>
                  <td>
                    <a href="#" @click.prevent="edit(hashtag.id)">edit</a>
                  </td>
                  <td>
                    <a href="#" @click.prevent="deleteHashtag(hashtag.id)">delete</a>
                  </td>
                </tr>
              </tbody>
          </table>
          <br />
          <c-validation-errors :errors-hash="errors" />
          <button @click="prev">戻る</button>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import{mapState, mapMutations} from 'vuex'

export default {
  data(){
    return {
      hashtags:[],
      errors:{},
    }
  },
  computed:{
    ...mapState(['loginFlg', 'account'])
  },
  mounted:function(){
    if(!this.loginFlg) {
      this.$toasted.info("no login! please log in.")
      this.$router.push( { path : '/' }, () => {} )
      return
    }
    this.get(this.Global.hashtag_division_id.official)
    this.get(this.Global.hashtag_division_id.free)
  },
  methods:{
    ...mapMutations([
      'setClientErrors'
    ]),
    get : function (divisionId) {
      this.axios.post(this.Global.url.api_mst_topics_search, {
        division_id : divisionId
      }).then(res => {
        console.dir(res.data)
        if (res.data.status == this.Global.api_status.success) {
          this.hashtags = this.hashtags.concat(res.data.hashtags)
          return
        }
        this.$toasted.error("admin : get hashtags failed")
      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/hashtag/index.vue::get", params: {division_id:divisionId}, errorMessage:error})
      })
    },
    edit : function (hashtagId) {
      this.$router.push( { path : '/admin/hashtag/edit/'+ hashtagId}, () => {} )
    },
  
    create : function () {
      this.$router.push( { path : '/admin/hashtag/edit/'+ 0} )
    },

    deleteHashtag : function (hashtagId) {
      this.axios.delete(this.Global.url.api_mst_topics_delete + "/" + hashtagId).then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.$toasted.info("completed")
          this.$router.push({ path : '/admin/menu' }, () => {})
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          console.dir(res.data.errors)
          this.errors = res.data.errors
          return
        } 

        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"admin/hashtag/index.vue::deleteHashtag", params: {}, errorMessage:JSON.stringify(res.data)})

      }).catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"admin/hashtag/index.vue::deleteHashtag", params: {}, errorMessage:error})
      })
    },
    prev : function(){
      this.$router.push( { path : '/admin' }, () => {} )
    }
  }
}
</script>
